<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-2 mt-2"
        fab
        dark
        x-small
        color="#3dbdad"
        v-bind="attrs"
        v-on="on"
        :to="{
          name: actionName,
          params: { id }
        }"
      >
        <v-icon>
          fas fa-eye
        </v-icon>
      </v-btn>
    </template>
    <span>Преглед</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "PreviewButton",
  props: ["actionName", "id"]
};
</script>

<style scoped></style>
