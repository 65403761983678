<template>
  <div>
    <v-card class="mx-auto" v-if="isLoadingRoutemaps">
      <v-card-title>
        Добре дошли в системата на Best Pharmaceuticals!
      </v-card-title>
    </v-card>
    <div v-else>
      <h1>Маршрутни карти</h1>
      <v-row class="mb-6 t-header">
        <template v-for="column of columns_to_show">
          <v-col
            :key="column.name"
            :cols="column.cols"
            v-if="column.hide != true"
          >
            <span @click="changeOrder(column.name)">
              {{ $t("LABELS." + column.name) }}
              <span v-if="column.name == headers.order">{{
                orderIcon
              }}</span></span
            >
          </v-col>
        </template>

        <v-col cols="1">
          Операции
        </v-col>
      </v-row>

      <div>
        <v-row class="mb-6 t-row" v-for="item of items" :key="item.id">
          <template v-for="col of columns_to_show">
            <v-col
              class="t-col"
              :key="col.name"
              :cols="col.cols"
              v-if="col.hide != true"
            >
              <span v-if="col.name == 'halls'">
                <span v-for="h in item[col.name]" v-bind:key="h.name">
                  {{ h.name }},
                </span>
              </span>
              <span v-else-if="col.name == 'startDate'">
                <span v-if="item[col.name] == ''">
                  {{ item.materialDate.substring(0, 10) }}
                </span>
                <span class="v-else">
                  {{ item.startDate.substring(0, 10) }}
                </span>
              </span>
              <span v-else>
                {{ item[col.name] }}
              </span>
            </v-col>
          </template>
          <v-col
            cols="1"
            class="t-col d-flex justify-content-start operation-container flex-wrap"
          >
            <EditButton
              v-if="user.role === 'Admin' || user.role === 'Manager'"
              :id="item.id"
              action-name="routemapsCreateUpdate"
            />
            <PreviewButton
              :action-name="
                user.role !== 'Hall'
                  ? 'routemapsActions'
                  : 'hallRoutemapActions'
              "
              :id="item.id"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            {{ totalItems }} {{ $t("A.RESULTS") }}
          </v-col>
        </v-row>
      </div>

      <div
        v-if="
          typeof error_messages === 'object' &&
            Object.keys(error_messages).length > 0
        "
        class="alert alert-danger"
      >
        <ul>
          <template v-for="(error, k) in error_messages">
            <span v-bind:key="k">
              <li v-for="(message, i) in error" v-bind:key="i">
                {{ message }}
              </li>
            </span>
          </template>
        </ul>
      </div>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingRoutemaps"
      ></v-progress-linear>

      <!-- <v-row v-if="totalPages > 0">
        <v-col class="text-center paginator">
          <v-btn
            class="m-1"
            v-for="page in visiblePages"
            :key="page.name"
            :disabled="page.isDisabled"
            depressed 
            small
            @click="headers.page = page.name"
          >
            {{ page.name }}
          </v-btn>
        </v-col>
      </v-row> -->
    </div>

    <div class="row d-none">
      <div class="col-xl-4 col-lg-4 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <Widget14_1></Widget14_1>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-4 col-lg-4 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <Widget14_2></Widget14_2>
          </template>
        </KTPortlet>
      </div>

      <div class="col-xl-4 col-lg-4 order-lg-2 order-xl-1">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <Widget14_3></Widget14_3>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Widget14_1 from "@/views/partials/widgets/Widget14_MiniDailySales.vue";
import Widget14_2 from "@/views/partials/widgets/Widget14_MiniProfitShare.vue";
import Widget14_3 from "@/views/partials/widgets/Widget14_MiniRevenueChange.vue";

import { GET_ROUTEMAPS, PAUSE_ROUTEMAP } from "@/store/routemaps.module";

import { mapGetters } from "vuex";

/**
 * Sample widgets data source
 */
import timelines from "@/common/mock/widget-timeline.json";

import PreviewButton from "@/components/ActionButtons/PreviewButton";
import EditButton from "@/components/ActionButtons/EditButton";

import widget5 from "@/common/mock/widget-5.json";

import moment from "moment";

export default {
  name: "dashboard",
  components: {
    KTPortlet,
    Widget14_1,
    Widget14_2,
    Widget14_3,
    PreviewButton,
    EditButton
  },
  computed: {
    ...mapGetters(["isLoadingRoutemaps"]),
    orderIcon: function() {
      let vm = this;
      return vm.headers.orderType == "asc" ? "▲" : "▼";
    },
    nextControlTime: function() {
      return (
        1000 *
        (parseInt(moment(this.active_routemap_times.nextControl).format("X")) -
          parseInt(moment().format("X")))
      );
    },
    pauseEndTime: function() {
      let now = moment().format("YYYY-MM-DD");
      return (
        1000 *
        (moment(
          now + " " + this.active_routemap_times.nextPause.to + ":00"
        ).format("X") -
          parseInt(moment().format("X")))
      );
    },
    operators: function() {
      return this.active_routemap.operators;
    },
    user: function() {
      return JSON.parse(window.localStorage.getItem("userData"));
    }
  },
  data() {
    return {
      chartData: [
        ["Бройки", "Бройки"],
        ["оставащи", 0],
        ["направени", 0]
      ],
      chartOptions: {
        chart: {},
        slices: {
          0: { color: "red" },
          1: { color: "#3ebeaf" }
        },
        pieSliceText: "value"
      },
      manager_code: "",
      error_messages: [],
      end_dialog: false,
      end_dialog_confirm: false,
      dialog: false,
      dialog_item: {},
      control_dialog: false,
      sign_dialog: false,
      sign_dialog_item: {
        code: ""
      },
      scrap_dialog: false,
      scrap_dialog_item: {
        code: ""
      },
      operator_dialog: false,
      operation_dialog: false,
      operation_dialog_action: 0,
      logout_dialog: false,
      pause: {
        comment: "",
        pause: "",
        code: "",
        needCleaning: false
      },
      action: "dashboard",
      timelines: timelines,
      widget5: widget5,
      tabIndex: 0,
      tabIndex2: 0,
      items: [],
      tableOptions: {
        showSearch: false,
        draggableCols: true,
        selectable: true,
        rowsPerPage: [2, 10, 20, 50],
        maxVisibleButtons: 5
      },
      active_routemap: {},
      active_routemap_times: {},
      totalItems: 0,
      totalPages: 0,
      shouldDoControl: false,
      new_control: {
        routeMapId: 0
      },
      new_operation: {
        routeMapId: 0,
        operationId: 0
      },
      columns_to_show: [
        {
          name: "id",
          cols: 1
        },
        {
          name: "status.name",
          cols: 1
        },
        {
          name: "product.name"
        },
        {
          name: "productManager.name",
          cols: 1
        },
        {
          name: "halls"
        },
        {
          name: "startDate"
        }
      ],
      headers: {
        columns: [
          {
            name: "id",
            search: ""
          },
          {
            name: "product.name",
            search: ""
          },
          {
            name: "halls",
            search: ""
          },
          {
            name: "productManager.name",
            search: ""
          },
          {
            name: "startDate",
            search: "",
            hide: true
          },
          {
            name: "materialDate",
            search: "",
            hide: true
          },
          {
            name: "status.name",
            search: ""
          },
          {
            name: "statusId",
            search: "[1,2,3,4]",
            hide: true
          }
        ],
        search: "",
        rows: 1000,
        page: 1,
        order: "id",
        orderType: "asc",
        activeRouteMaps: true
      },
      isPaused: false
    };
  },
  mounted() {
    let vm = this;
    vm.$store.dispatch(SET_BREADCRUMB, [
      { title: vm.$i18n.t("MENU.DASHBOARD") }
    ]);

    if (vm.user.role == "Labourer") {
      vm.$router.push({ name: "loadList" });
    }

    vm.fetchData();
    // this.getRoutemapActive();

    // let int_time = 60 * 60 * 83;

    // setInterval(() => {
    //     vm.getRoutemapActive();
    // }, int_time);

    // vm.isPause();
  },
  methods: {
    fetchData: _.debounce(function() {
      let vm = this;

      this.getOptions;

      vm.$store
        .dispatch(GET_ROUTEMAPS, { payload: vm.headers })
        .then(data => {
          vm.items = data.data.data;
          vm.totalItems = data.data.recordsFiltered;
          vm.totalPages = data.data.totalPages;

          // if( vm.totalItems > 0) {
          //     vm.getRoutemapActive();
          // }
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "routemapsList" });
          }
        });
    }, 500),
    transform: function(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;

        props[key] = `${digits}`;
      });

      return props;
    },
    changeOrder: _.debounce(function(key) {
      let vm = this;

      if (vm.headers.order == key) {
        let oType = vm.headers.orderType == "asc" ? "desc" : "asc";

        vm.headers.orderType = oType;
      } else {
        vm.headers.order = key;
      }

      vm.headers.page = 1;
      window.localStorage.setItem(vm.action + "_order", vm.headers.order);
      window.localStorage.setItem(
        vm.action + "_orderType",
        vm.headers.orderType
      );

      vm.fetchData();
    }, 250),

    doPause: function() {
      let vm = this;
      if (vm.dialog_item.statusId == 1 || vm.dialog_item.statusId == 3) {
        vm.pause.pause = false;
      } else {
        vm.pause.pause = true;
      }

      this.error_messages = [];

      vm.$store
        .dispatch(PAUSE_ROUTEMAP, { id: vm.dialog_item.id, payload: vm.pause })
        .then(() => {
          vm.pause = {
            comment: "",
            pause: "",
            needCleaning: false
          };

          vm.dialog = false;
          setTimeout(() => {
            vm.fetchData();
            vm.getRoutemapTimeInfo();
          }, 250);
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    }
  }
};
</script>
